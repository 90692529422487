<template>
                          
            <IPInfo
              :ipv4="ipv4"
              :ipv4Loading="ipv4Loading"
              :ipv6="ipv6"
              :ipv6Loading="ipv6Loading"
              :isp="isp"
              :isplink="isplink"
              :countrylink="countrylink"
              :countryregd="countryregd"
              :registrylink="registrylink"
              :registry="registry"
              :extendedLoading="extendedLoading"           
            />

            <IPExtended
              :ipv4="ipv4"
              :ipv4Loading="ipv4Loading"
              :network="network"
              :prefix="prefix"
              :addresses_total="addresses_total"
              :addresses_useable="addresses_useable"
              :rangestart="rangestart"
              :rangeend="rangeend"
              :isp="isp"
              :isplink="isplink"
              :countrylink="countrylink"
              :registrylink="registrylink"
              :asn="asn"
              :ipv6="ipv6"
              :ipv6Loading="ipv6Loading"
              :privateIp="privateIp"
              :privateIpLoading="privateIpLoading"
              :clientPort="clientPort"
              :extendedLoading="extendedLoading"
            />                            

            <IPLocation
              :havelocation="havelocation"
              :locality="locality"
              :subdiv="subdiv"
              :city="city"
              :country="country"
              :countryiso="countryiso"
              :continent="continent"
              :postcode="postcode"
              :latitude="latitude"
              :longitude="longitude"
              :timezone="timezone"
              :extendedLoading="extendedLoading"
              :images="images"
              :extra="extra"
              :extrapresent="extrapresent"
              :basepath="basepath"
              :locationrefresh="locationrefresh"
              :locationtitle="locationtitle"
              :locationcaption1="locationcaption1"
              :locationcaption2="locationcaption2"
              :locationbadge="locationbadge"
              @getloc="getLoc"
            />

            <IPTravel
              :travelAdvice="travelAdvice"
              :travelAdviceLoading="travelAdviceLoading"
              :travelLocation="travelLocation"
              :travelLocationFull="travelLocationFull"
              :travelPermaLink="travelPermaLink"
            />

            <IPBrowser
              :userAgent="userAgent"
              :browserName="browserName"
              :browserVersion="browserVersion"
              :osName="osName"
              :osVersion="osVersion"
              :osVersionName="osVersionName"
              :platformType="platformType"
              :platformVendor="platformVendor"
              :platformModel="platformModel"
              :engineName="engineName"
              :engineVersion="engineVersion"
              :acceptEncoding="acceptEncoding"
              :acceptLanguage="acceptLanguage"
              :extendedLoading="extendedLoading"
            />


        
            <q-card class="myip-info-card" >
              <q-item class="myip-item-cardtitle">
                <q-item-section avatar>
                    <q-avatar square ><img src="../assets/question-mark.svg" width="40" height="40" alt="Internet terminology help"></q-avatar>
                </q-item-section>
                <q-item-section>
                  <q-item-label class="myip-info-title text-h5">Help!</q-item-label>
                  <q-item-label caption>
                    Too many acronyms? Get some Internet KnowHow here.
                  </q-item-label>
                </q-item-section>
              </q-item>

              <div class="myip-footer-links">
                  <a href="https://knowledge-base.myip.ai/kb/ipv4/" class="myip-footer-link">What is IPv4?</a>
                  <a href="https://knowledge-base.myip.ai/kb/ipv6/" class="myip-footer-link">What is IPv6?</a>
                  <a href="https://knowledge-base.myip.ai/categories/travel-advice/" class="myip-footer-link">Travel Guide Database</a>
                  <a href="https://knowledge-base.myip.ai/categories/autonomous-system-numbers-asn/" class="myip-footer-link">ASN Database</a>
                  <a href="https://knowledge-base.myip.ai/" class="myip-footer-link">Knowledge Base</a>
              </div>          
            </q-card>            


</template>

<script setup>
  import { fetchIPX } from '../utils.js';
  import { ref, onMounted } from 'vue';
  import IPInfo from '../components/IPInfo.vue';
  import IPExtended from '../components/IPExtended.vue';
  import IPLocation from '../components/IPLocation.vue';
  import IPBrowser from '../components/IPBrowser.vue';
  import IPTravel from '../components/IPTravel.vue';

  //IpInfo
  const ipv4 = ref('');
  const ipv4Loading = ref(true);
  const ipv6 = ref('');
  const ipv6Loading = ref(true);
  const privateIp = ref('');
  const privateIpLoading = ref(true);
  //plus isp, country (and extendedLoading)

  //IPExtended
  const network = ref('');
  const prefix = ref(0);
  const addresses_total = ref(0);
  const addresses_useable = ref(0);
  const rangestart = ref('');
  const rangeend = ref('');
  const isp = ref('');
  const isplink = ref('');
  const countrylink = ref('');
  const countryregd = ref('');
  const registrylink = ref('');
  const registry = ref('');
  const asn = ref(0);
  const clientPort = ref('');
  const extendedLoading = ref(true);
  //plus ipv4, ipv6, privateIP (and extendedLoading)

  //IPLocation
  const havelocation = ref(0);
  const locality = ref('');
  const subdiv = ref('');
  const city = ref('');
  const country = ref('');
  const countryiso = ref('');
  const continent = ref('');
  const postcode = ref('');
  const latitude = ref(0);
  const longitude = ref(0);
  const timezone = ref('');
  const images = ref([]);
  const extra = ref({});
  const extrapresent = ref(false);
  //and extendedLoading

  //IPBrowser
  const userAgent = ref('');
  const browserName = ref('');
  const browserVersion = ref('');
  const osName = ref('');
  const osVersion = ref('');
  const osVersionName = ref('');
  const platformType = ref('');
  const platformVendor = ref('');
  const platformModel = ref('');
  const engineName = ref('');
  const engineVersion = ref('');
  const acceptEncoding = ref('');
  const acceptLanguage = ref('');
  //and extendedLoading

  //IPTravel
  const travelAdvice = ref('');
  const travelAdviceLoading = ref(true);
  const travelLocation = ref('');
  const travelLocationFull = ref('');
  const travelPermaLink = ref('');
  
  const basepath = ref('images/');

  const locationrefresh = ref(false);
  const locationtitle = ref('');
  const locationcaption1 = ref('');
  const locationcaption2 = ref('');
  const locationbadge = ref('');

  const suppresslocationupdate = ref(false);  
  
  const locSuccess = async(pos) => {
    const crd = pos.coords;

    console.log(crd);

    var devmode = process.env.NODE_ENV=='development';
    var pth = '';

    try {
      suppresslocationupdate.value = true;
      console.log('getting pos');
      if (!devmode){
        pth = 'https://myip.ai/api/ip4';
      }else{
        pth = 'http://localhost:3000/api/ip4';
      }
      const data = await fetchIPX(pth, crd.latitude, crd.longitude);
      
      havelocation.value = data.have_location_data;
      locality.value = data.locality;
      subdiv.value = data.subdiv;
      city.value = data.city;
      country.value = data.country;
      countryiso.value = data.country_iso;
      continent.value = data.continent;
      postcode.value = data.postcode;
      latitude.value = data.latitude;
      longitude.value = data.longitude;
      timezone.value = data.timezone;

      if (data.extra!=''){
        const extraData = JSON.parse(data.extra);
        images.value = extraData.Images;
        extraData.Images = [];
        extra.value = extraData;
        extrapresent.value = true;
      }

      locationtitle.value = 'Your Location';
      locationcaption1.value = "Based on your device's position";
      locationcaption2.value = '<div class="myip-loccap-accurate">REFRESH</div>';
      locationbadge.value = 'LOCATION';

    } catch (err) {
      console.error('Error refreshing position:', err);
    } finally {
      locationrefresh.value = false;
    }        
    
    try {    
      travelAdviceLoading.value = true;
      console.log('getting fun');
      if (!devmode){
        pth = 'https://myip.ai/api/ip3';
      }else{
        pth = 'http://localhost:3000/api/ip3';
      }
      const data = await fetchIPX(pth, 0, -1);
      travelAdvice.value = data.answer;
      travelLocation.value = data.location;
      travelLocationFull.value = data.location_full;
      travelPermaLink.value = data.permalink;
      
    } catch (err) {
      console.error('Error fetching fun:', err);
    } finally {
      travelAdviceLoading.value = false;
    }
  };

  const locError = (err) => {
    //need a prompt here depending on the error
    console.log(`ERROR(${err.code}): ${err.message}`);
    switch (err.code){
      case 1:        
        locationcaption2.value = '<div class="myip-loccap-approx">Your device denied permission, RETRY</div>';  
        break;
      case 2:
        locationcaption2.value = '<div class="myip-loccap-approx">Position unavailable, RETRY</div>';        
        break;
      case 3:
        locationcaption2.value = '<div class="myip-loccap-approx">GetPosition timed out, RETRY</div>';        
        break;
    }
    locationrefresh.value = false;
  };
  const locOptions = {
    enableHighAccuracy: true,
    timeout: 30000,
    maximumAge: 0,
  };
  const getLoc = () =>{
    console.log("pos");
    locationrefresh.value = true;
    navigator.geolocation.getCurrentPosition(locSuccess, locError, locOptions);
  }

  onMounted(async () => {

    // ** PROD BLOCK          
    //with each await below the promise is started before the await by assigning to a const
    //this enables parallel (ish) execution of getLocalIPAddress and the subdomain fetch
    //doing it the other way (one await after another) we have to wait until each await has fulfilled
    //which is sequential and undesirable as any one of the fetch's could hold up user info being displayed
    
    document.title = 'What is my IP address? | Find your IP address using MyIP.ai';
    
    var devmode = process.env.NODE_ENV=='development';
    var pth = '';

    locationtitle.value='Approximate Location ..';
    locationcaption1.value='..based on your IP Address';
    locationcaption2.value='<div class="myip-loccap-approx">Click HERE for more accuracy</div>';
    locationbadge.value = 'LOCATION (APPROX)';      

    try {
      console.log('getting ipv4');
      if (!devmode){
        pth = 'https://myip.ai/api/ip';
      }else{
        pth = 'http://localhost:3000/api/ip';
      }
      const p4 = fetchIPX(pth, -1, -1);
      ipv4.value = (await p4).ip;
    } catch (err) {
      console.error('Error fetching ipv4:', err);
    } finally {
      ipv4Loading.value = false;
    }        
    
    try {
      console.log('getting ipv6');
      if (!devmode){
        const p6 = fetchIPX('https://ipv6.myip.ai', -1, -1);
        ipv6.value = (await p6).ip;              
      }else{
        ipv6.value = '2001:0db8:85a3:0000:0000:8a2e:0370:7334';      
      }      
    } catch (err) {
      console.error('Error fetching ipv6:', err);
    } finally {
      //ipv6.value = "2001:0db8:85a3:0000:0000:8a2e:0370:7334:0000:8a2e:0370:7334";
      ipv6Loading.value = false;
    }        

    try {
      console.log('getting localip');
      const pip = getLocalIPAddress();
      privateIp.value = await pip; 
    } catch (err) {
      console.error('Error fetching private IP:', err);
    } finally {
      privateIpLoading.value = false;
    }          

    try {
      console.log('getting ip extended');
      if (!devmode){
        pth = 'https://myip.ai/api/ip2';
      }else{
        pth = 'http://localhost:3000/api/ip2';
      }
      const data = await fetchIPX(pth, -1, -1);

      network.value = data.network;
      prefix.value = data.prefix;
      addresses_total.value = data.numaddress_total;
      addresses_useable.value = data.numaddress_useable;
      rangestart.value = data.rangestart;
      rangeend.value = data.rangeend;
      isp.value = data.isp;
      isplink.value = data.isplink;
      countrylink.value = data.countrylink;
      countryregd.value = data.countryregd;
      registrylink.value = data.registrylink;
      registry.value = data.registry;
      asn.value = data.asn;
      clientPort.value = data.client_port;

      havelocation.value = data.have_location_data;
      locality.value = data.locality;
      subdiv.value = data.subdiv;
      city.value = data.city;
      country.value = data.country;
      countryiso.value = data.country_iso;
      continent.value = data.continent;
      postcode.value = data.postcode;
      latitude.value = data.latitude;
      longitude.value = data.longitude;
      timezone.value = data.timezone;

      if (data.extra!=''){
        const extraData = JSON.parse(data.extra);
        images.value = extraData.Images;
        extraData.Images = [];
        extra.value = extraData;
        extrapresent.value = true;
      }
      
      userAgent.value = data.user_agent;
      browserName.value = data.browser_name;
      browserVersion.value = data.browser_version;
      osName.value = data.os_name;
      osVersion.value = data.os_version;
      osVersionName.value = data.os_versionName;
      platformType.value = data.platform_type;
      platformVendor.value = data.platform_vendor;
      platformModel.value = data.platform_model;
      engineName.value = data.engine_name;
      engineVersion.value = data.engine_version;
      acceptEncoding.value = data.accept_encoding;
      acceptLanguage.value = data.accept_language;
      
      console.log(data);
    } catch (err) {
      console.error('Error fetching extended:', err);
    } finally {
      extendedLoading.value = false;
    }        

    try {
      console.log('getting fun');
      if (!devmode){
        pth = 'https://myip.ai/api/ip3';
      }else{
        pth = 'http://localhost:3000/api/ip3';
      }
      const data = await fetchIPX(pth, 0, -1);
      
      if (!suppresslocationupdate.value) {        
        travelAdvice.value = data.answer;
        travelLocation.value = data.location;
        travelLocationFull.value = data.location_full;
        travelPermaLink.value = data.permalink;
      }

      console.log(data);
    } catch (err) {
      console.error('Error fetching fun:', err);
    } finally {
      travelAdviceLoading.value = false;
    }        
    //console.log($q);

  });

  
  /* eslint-disable  no-unused-vars */

  //also scan for local devices - all these tools need easy abouts
  const getLocalIPAddress = () => {
    return new Promise((resolve, reject) => {
      const pc = new RTCPeerConnection({ iceServers: [] });
      pc.createDataChannel("");
      pc.createOffer()
        .then((sdp) => pc.setLocalDescription(sdp))
        .catch(reject);
      pc.onicecandidate = (ice) => {
        if (!ice || !ice.candidate || !ice.candidate.candidate) {
          return;
        }
        console.log(ice);
        
        var candidate = null;
        const regexResult = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/.exec(ice.candidate.candidate);
        if (regexResult !== null) {
          candidate = regexResult[1];
        }

        if (candidate) {
          resolve(candidate);
        } else {
          reject(new Error("Could not get private IP"));
        }
        pc.close();
      };
    });
  }
  /* eslint-enable no-unused-vars */

</script>